/* eslint-disable react/no-direct-mutation-state */
import '../styles/DeliveryModal.css';
import Order from '../model/order';
import enumRequestState from '../model/enums/request_state';
import ModalBodySender from "./DeliveryModalSender";
import ModalBodyReceiver from "./DeliveryModalReceiver";
import ModalBodyComment from "./DeliveryModalComment";
import ModalBodySubmit from "./DeliveryModalSubmit";
import { disableScroll, enableScroll } from '../common/effectsHelper';
var createReactClass = require('create-react-class');

var DeliveryModal = createReactClass({
    getInitialState: function () {
        let _order = new Order();
        _order.state = enumRequestState.PENDING_FOR_DELIVERY;
        let _showAddresses = false;
        if (this.props.user) {
            this.props.dbHandler.getAddressesByIdsDB(this.props.user.addresses, this.onAddressesLoad)
            _showAddresses = true;
        }
        if (this.props.client) {
            _order.senderAddress = this.props.client.locations[0];
            _order.senderAddress.name = this.props.client.name;
        }
        if (this.props.cart) { //TODO: Move this to server side!
            _order.cart = this.props.cart;
            _order.state = enumRequestState.PENDING_FOR_CLIENT;
        }
        return {
            isOpen: true,
            selectedContent: "receiver",
            order: _order,
            waiting_for_server_respond: true,
            send_success: true,
            error_message: "",
            dbHandler: this.props.dbHandler,
            captchaEnabled: false,
            onlyReceiver: this.props.onlyReceiver,
            client: this.props.client,
            user: this.props.user,
            addresses: [],
            loadingAddresses: true,
            showAddresses: _showAddresses,
            wrongAddress: false,
            wrongName: false,
            wrongPhone: false,
            enableSubmit: true,
            commentOptional: this.props.cart ? true : false,
        }
    },
    componentDidMount() {
        if (this.props.authManager) {
            this.props.authManager.installInvisibleReCaptcha("submit-button", this.onReCaptchaChange);
        }
        else {
            console.log("Auth Manager is not initialized!");
        }
        disableScroll();
    },
    componentWillUnmount() {
        enableScroll();
    },
    handleClose: function (orderSent) {
        this.setState({ isOpen: false });
        this.props.onClose(orderSent && this.state.send_success);
    },
    onUpdateOrderDbFinish: function (message) {
        if (message) {
            this.setState({
                waiting_for_server_respond: false,
                send_success: false,
                error_message: ""
            });
        }
        else {
            this.setState({
                waiting_for_server_respond: false,
                send_success: true,
                error_message: ""
            });
        }
    },
    onAddressesLoad: function (addresses) {
        if (addresses && Array.isArray(addresses)) {
            this.setState({
                addresses: addresses,
                loadingAddresses: false
            });
        }
        else {
            this.setState({
                addresses: [],
                loadingAddresses: false
            });
        }
    },
    onReCaptchaChange: function () {
        this.setState({ captchaEnabled: true });
    },
    changeSelectedContent: function (go_forward) {
        if (go_forward) {
            switch (this.state.selectedContent) {
                case "receiver":
                    if (this.validateInputValues()) {
                        if (this.state.onlyReceiver) {
                            this.setState({
                                selectedContent: "comment",
                                enableSubmit: this.props.cart ? true : false,
                            });
                        }
                        else {
                            this.setState({ selectedContent: "sender" });
                        }
                    }
                    break;
                case "sender":
                    if (this.validateInputValues()) {
                        this.setState({
                            selectedContent: "comment",
                            enableSubmit: this.props.cart ? true : false,
                        });
                    }
                    break;
                case "comment":
                    if (this.state.captchaEnabled) {
                        this.setState({ selectedContent: "submit" });
                        this.state.order.receiverAddressId = this.state.order.receiverAddress.Id;
                        this.state.order.senderAddressId = this.state.order.senderAddress.Id;
                        this.state.dbHandler.addOrUpdateOrderDB(this.state.order, this.onUpdateOrderDbFinish);
                    }
                    else {
                        console.log("ReCaptcha is not solved.");
                    }
                    break;
                case "submit":
                    this.handleClose(true);
                    break;
                default:
                    break;
            }
        }
        else {
            switch (this.state.selectedContent) {
                case "receiver":
                    this.handleClose(false);
                    break;
                case "sender":
                    this.setState({ selectedContent: "receiver" });
                    break;
                case "comment":
                    if (this.state.onlyReceiver) {
                        this.setState({ selectedContent: "receiver" });
                    }
                    else {
                        this.setState({ selectedContent: "sender" });
                    }
                    break;
                default:
                    break;
            }
        }
    },
    validateInputValues: function () {
        const address = (this.state.selectedContent === "sender" ? this.state.order.senderAddress : this.state.order.receiverAddress);
        let _wrongAddress = false;
        let _wrongName = false;
        let _wrongPhone = false;

        if (address.address) {
            if (address.address.length > 100) {
                _wrongAddress = true;
            }
        }
        else {
            _wrongAddress = true;
        }

        if (address.name) {
            if (address.name.length > 40) {
                _wrongName = true;
            }
        }
        else {
            _wrongName = true;
        }

        if (address.phone) {
            address.phone = address.phone.replace(/ /g, "");
            if (!address.phone.match(/^[0-9\-=+]{5,18}$/)) {
                _wrongPhone = true;
            }
        }
        else {
            _wrongPhone = true;
        }

        this.setState({
            wrongAddress: _wrongAddress,
            wrongName: _wrongName,
            wrongPhone: _wrongPhone,
        });

        return (!_wrongAddress && !_wrongName && !_wrongPhone);
    },
    onCommentChange: function () {
        if (!this.props.cart) {
            if (this.state.order.comment.length < 5 && this.state.enableSubmit) {
                this.setState({ enableSubmit: false });
            }
            else if (this.state.order.comment.length > 5) {
                this.setState({ enableSubmit: true });
            }
        }
    },
    getLeftButtonText: function () {
        switch (this.state.selectedContent) {
            case "receiver":
                return ("Otkaži");
            case "sender":
            case "comment":
                return ("Prethodno");
            default:
                return ("Ok");
        }
    },
    getRightButtonText: function () {
        switch (this.state.selectedContent) {
            case "receiver":
            case "sender":
                return ("Sledeće");
            case "comment":
                return ("Zakaži");
            default:
                return ("Ok");
        }
    },
    onSkipSenderChange: function (value) {
        if (value) {
            this.state.order.generateUndefinedSender();
        }
        else {
            this.state.order.clearUndefinedSender();
        }
        this.setState({ onlyReceiver: value });
    },
    render: function () {
        let content = null;
        switch (this.state.selectedContent) {
            case "receiver":
                content = <ModalBodyReceiver
                    Order={this.state.order}
                    addresses={this.state.addresses}
                    loadingAddresses={this.state.loadingAddresses}
                    showAddresses={this.state.showAddresses}
                    wrongAddress={this.state.wrongAddress}
                    wrongName={this.state.wrongName}
                    wrongPhone={this.state.wrongPhone}
                    onSkipSenderChange={this.onSkipSenderChange}
                    onlyReceiver={this.state.onlyReceiver}
                    hideCheckBox={this.props.onlyReceiver}
                />;
                break;
            case "sender":
                content = <ModalBodySender Order={this.state.order}
                    wrongAddress={this.state.wrongAddress}
                    wrongName={this.state.wrongName}
                    wrongPhone={this.state.wrongPhone} />;
                break;
            case "comment":
                content = <>
                    <ModalBodyComment
                        Order={this.state.order}
                        onChange={this.onCommentChange}
                        optional={this.state.commentOptional} />
                </>;
                break;
            case "submit":
                content = <>
                    <ModalBodySubmit
                        waitingForRespond={this.state.waiting_for_server_respond}
                        success={this.state.send_success}
                        errorMessage={this.state.error_message} />
                </>;
                break;
            default:
                break;
        }
        return (
            <div
                className="delivery-modal-container "
                open={this.state.isOpen}
                onClose={() => { this.handleClose(false) }}
            >
                <div className="dialog-modal">
                    <div className="dialog-header">
                        <h2>Zakaži dostavu</h2>
                        {this.state.selectedContent === "submit" ? null :
                            <span className="close-modal" onClick={() => { this.handleClose(false) }}>
                                <h1>&times;</h1>
                            </span>}
                    </div>
                    <div className="dialog-content">
                        {content}
                    </div>
                    <div className="dialog-footer">
                        {this.state.selectedContent !== "submit" ?
                            <button onClick={this.changeSelectedContent.bind(this, false)} className="send-request">
                                {this.getLeftButtonText()}
                            </button> : null}
                        <button
                            id="submit-button"
                            onClick={this.changeSelectedContent.bind(this, true)}
                            className={("send-request " + (((this.state.selectedContent === "submit" && this.state.waiting_for_server_respond) || !this.state.enableSubmit) ? "disabled" : ""))}
                            disabled={((this.state.selectedContent === "submit" && this.state.waiting_for_server_respond) || !this.state.enableSubmit)}>
                            {/* className={("send-request " + (((this.state.selectedContent === "submit" && this.state.waiting_for_server_respond) ||
                                (this.state.selectedContent === "comment" && !this.state.captchaEnabled)) ? "disabled" : ""))}
                            disabled={(this.state.selectedContent === "submit" && this.state.waiting_for_server_respond) ||
                                (this.state.selectedContent === "comment" && !this.state.captchaEnabled)}> */}
                            {this.getRightButtonText()}
                        </button>
                    </div>
                </div>
            </div>

        )
    }
})

export default DeliveryModal
