/* eslint-disable react/no-direct-mutation-state */
import MyProfileContent from './MyProfileContent.jsx';
import '../styles/MyProfile.css';
import '../styles/MainStyle.css';
import '../styles/AsideMenu.css';
import AsideMenu from './AsideMenu.jsx';
import Loader from "./Loader";
import InputField from '../common/InputField';
import MessageBox from "../common/MessageBox";
import User from "../model/user.ts";
import OrderInfoModal from './OrderInfoMadal.jsx';
var createReactClass = require('create-react-class');

var MyProfile = createReactClass({
    getInitialState: function () {
        //this.props.dbHandler.getOrdersByIdsDB(_user.orders, 0, false, this.onDbFinish);
        let _user = this.props.user;
        if (!_user) {
            _user = new User();
        }
        return {
            selectedMenu: "info",
            loadingDb: false,
            orders: [],
            favorites: [],
            addresses: [],
            user: _user,
            userName: _user.name,
            email: _user.email,
            editUserName: false,
            editEmail: false,
            errorOccurred: false,
            errorMessage: "",
            authInitDone: this.props.authInitDone,
            ordersByClient: true,
            showOrderInfoModal: false,
            selectedOrder: null,
            // TODO: Move this to settings when we make global site settings.
            enableNotifications: _user.settings.enableNotifications === undefined ? true : _user.settings.enableNotifications,
            enableNotificationSound: _user.settings.enableNotificationSound === undefined ? true : _user.settings.enableNotificationSound,
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                user: this.props.user,
                userName: this.props.user.name,
                email: this.props.user.email,
                enableNotifications: this.props.user.settings.enableNotifications === undefined ? true : this.props.user.settings.enableNotifications,
                enableNotificationSound: this.props.user.settings.enableNotificationSound === undefined ? true : this.props.user.settings.enableNotificationSound,
            });
        }
        if (this.props.authInitDone !== prevProps.authInitDone) {
            this.setState({
                authInitDone: this.props.authInitDone
            });
        }
    },
    componentDidMount: function () {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
    },
    componentWillUnmount: function () {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    },
    onBackButtonEvent: function (event) {
        event.preventDefault();
        if (this.props.backFunction) {
            this.props.backFunction();
        }
        else {
            console.log("Back function is not set!");
        }
    },
    onMenuSelectionChange: function (menuId) {
        switch (menuId) {
            case "favorites":
                this.props.dbHandler.getClientsByIdsDB(this.state.user.favorites, 0, false, this.onDbFinish)
                this.setState({
                    loadingDb: true,
                    selectedMenu: menuId
                });
                break;
            case "orderHistory":
                this.props.dbHandler.getOrdersByIdsDB(this.state.user.orders, 0, false, this.onDbFinish);
                this.setState({
                    loadingDb: true,
                    selectedMenu: menuId
                });
                break;
            case "addresses":
                this.props.dbHandler.getAddressesByIdsDB(this.state.user.addresses, this.onDbFinish);
                this.setState({
                    loadingDb: true,
                    selectedMenu: menuId
                });
                break;
            default:
                this.setState({ selectedMenu: menuId });
                break;
        }
    },
    onDbFinish: function (data) {
        switch (this.state.selectedMenu) {
            case "favorites":
                this.setState({
                    loadingDb: false,
                    favorites: data,
                });
                break;
            case "orderHistory":
                const sortedOrders = [].concat(data)
                    .sort((a, b) => a.state > b.state ? 1 : -1);
                this.setState({
                    loadingDb: false,
                    orders: sortedOrders
                });
                break;
            case "addresses":
                this.setState({
                    loadingDb: false,
                    addresses: data,
                });
                break;
            default:
                this.setState({ loadingDb: false });
                break;
        }
    },
    onCardDelete: function (obj) {
        let index;
        switch (this.state.selectedMenu) {
            case "favorites":
                const favCopy = [...this.state.user.favorites];
                index = favCopy.indexOf(obj.id)
                if (index !== -1) {
                    favCopy.splice(index, 1);
                    this.state.user.favorites = favCopy;
                }
                this.props.dbHandler.updateUserDB(this.state.user, null);
                break;
            case "orderHistory":
                break;
            case "addresses":
                const addressesCopy = [...this.state.user.addresses];
                index = addressesCopy.indexOf(obj.id)
                if (index !== -1) {
                    addressesCopy.splice(index, 1);
                    this.state.user.addresses = addressesCopy;
                }
                this.props.dbHandler.updateUserDB(this.state.user, null);
                break;
            default:
                break;
        }
    },
    onChange: function (event) {
        switch (event.target.id) {
            case "notification-off":
                if (event.target.checked) {
                    this.setState({ enableNotifications: event.target.checked });
                }
                else { // If notifications are disabled, disable sound for them.
                    this.setState({
                        enableNotifications: event.target.checked,
                        enableNotificationSound: event.target.checked
                    });
                    this.state.user.settings.enableNotificationSound = event.target.checked;
                }
                this.state.user.settings.enableNotifications = event.target.checked;
                this.props.dbHandler.updateUserDB(this.state.user, null);
                break;
            case "sound-off":
                this.setState({ enableNotificationSound: event.target.checked });
                this.state.user.settings.enableNotificationSound = event.target.checked;
                this.props.dbHandler.updateUserDB(this.state.user, null);
                break;
            default:
                this.setState({ [event.target.id]: event.target.value });
                break;
        }
    },
    onClick: function (id, inputId) {
        switch (id) {
            case "confirm":
                if (this.state.editUserName && inputId === "userName") {
                    if (this.validateUserName()) {
                        this.state.user.name = this.state.userName;
                        this.props.dbHandler.updateUserDB(this.state.user, null);
                        this.props.authManager.updateUserName(this.props.authManager.getUserFirebase(), this.state.user.name);
                        this.setState({ editUserName: false });
                    }
                    else {
                        this.setState({
                            editUserName: false,
                            userName: this.state.user.name,
                            errorMessage: "Korisničko ime nije ispravno!",
                            errorOccurred: true,
                        });
                    }
                }
                else if (this.state.editEmail && inputId === "email") {
                    if (this.validateEmail()) {
                        if (!this.state.user.email) {
                            // Add 10 Gift points for first email add.
                            // add... malo morgen.
                            //this.state.user.giftPoints = Number(this.state.user.giftPoints) + 10;
                        }
                        this.state.user.email = this.state.email;
                        this.props.dbHandler.updateUserDB(this.state.user, null);
                        this.setState({ editEmail: false });
                    }
                    else {
                        this.setState({
                            editEmail: false,
                            email: this.state.user.email,
                            errorMessage: "Email adresa nije napisana u odgovarajućem formatu!",
                            errorOccurred: true,
                        });
                    }
                }
                break;
            case "cancel":
                if (this.state.editUserName && inputId === "userName") {
                    this.setState({
                        editUserName: false,
                        userName: this.state.user.name
                    });
                }
                else if (this.state.editEmail && inputId === "email") {
                    this.setState({
                        editEmail: false,
                        email: this.state.user.email
                    });
                }
                break;
            default:
                break;
        }
    },
    validateEmail: function () {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.state.email);
    },
    validateUserName: function () {
        if (this.state.userName) {
            if (this.state.userName.length > 20) {
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    },
    onContentClick: function (arg) {
        switch (this.state.selectedMenu) {
            case "favorites":
                this.props.onClientSelection(arg);
                break;
            case "orderHistory":
                this.setState({
                    showOrderInfoModal: true,
                    selectedOrder: arg,
                });
                break;
            case "addresses":
                break;
            default:
                break;
        }
    },
    render: function () {
        const showLoader = (this.state.loadingDb || !this.state.authInitDone);
        let content;
        switch (this.state.selectedMenu) {
            case "orderHistory":
                content =
                    <>
                        {/* Kartica uvek ima "order-filer-div", a selektovana kartica dobija i klasu "selected-filter" */}
                        <div className="my-profile-orders-filter">
                            <div className={("order-filer-div " + (this.state.ordersByClient ? " selected-filter" : "unselected-filter"))}
                                onClick={() => { this.setState({ ordersByClient: true }) }}>Porudžbine</div>
                            <div className={("order-filer-div " + (!this.state.ordersByClient ? " selected-filter" : "unselected-filter"))}
                                onClick={() => { this.setState({ ordersByClient: false }) }}>Zakazivanje kurira</div>
                        </div>
                        <div className="my-profile-orders">
                            {this.state.orders.map((order) => {
                                if (this.state.ordersByClient) {
                                    if (!order.cart.isEmpty()) {
                                        return (
                                            <MyProfileContent order={order}
                                                onClick={this.onContentClick} />
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                                }
                                else {
                                    if (order.cart.isEmpty()) {
                                        return (
                                            <MyProfileContent order={order}
                                                onClick={this.onContentClick} />
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                                }

                            })}
                        </div>
                    </>;
                break;
            case "favorites":
                content =
                    <>
                        <h1>Omiljeni lokali</h1>
                        <div className="my-profile-favorite-store">
                            {this.state.favorites.map((client) => {
                                return (
                                    <MyProfileContent client={client}
                                        onDelete={this.onCardDelete.bind(this, client)}
                                        onClick={this.onContentClick} />
                                );
                            })}
                        </div>
                    </>;
                break;
            case "addresses":
                content =
                    <>
                        <h1>Moje adrese</h1>
                        <div className="my-profile-address">
                            {this.state.addresses.map((address) => {
                                return (
                                    <MyProfileContent address={address}
                                        onDelete={this.onCardDelete.bind(this, address)} />
                                );
                            })}
                        </div>
                    </>;
                break;
            case "info":
                content =
                    <>
                        {this.state.errorOccurred ?
                            <MessageBox title="Greška!" content={this.state.errorMessage} onClick={() => { this.setState({ errorOccurred: false, errorMessage: "" }); }} /> : <></>}
                        <div className="my-profile-info border-radius">
                            <div className="my-profile-info-content">
                                <div>
                                    <p>IME:</p>
                                    {this.state.editUserName ?
                                        <>
                                            {InputField("text", "Ime", this.state.userName, "userName", this.onChange, this.onClick)}
                                        </> :
                                        <>
                                            <h1>{this.state.user.name}</h1>
                                            <button className="my-profile-make-changes-button" onClick={() => { this.setState({ editUserName: true }) }}>
                                                <i><img src="images/small_img/pencil.svg" alt="Izmeni unos ikona" /></i>
                                                <p>IZMENI</p>
                                            </button>
                                        </>}
                                </div>
                                <div>
                                    <p>TELEFON:</p>
                                    <h1>{this.state.user.phone}</h1>
                                    <i><img src="images/small_img/info.svg" alt="Info ikona" /></i>
                                </div>
                                <div>
                                    <p>E-MAIL:</p>
                                    {this.state.editEmail ?
                                        <>
                                            {InputField("email", "e-mail", this.state.email, "email", this.onChange, this.onClick)}
                                        </> :
                                        <>
                                            {this.state.user.email ?
                                                <h1>{this.state.user.email}</h1> :
                                                <h1> Unesite e-mail adresu</h1>}
                                            <button className="my-profile-make-changes-button" onClick={() => { this.setState({ editEmail: true }) }}>
                                                <i><img src="images/small_img/pencil.svg" alt="Izmeni unos ikona" /></i>
                                                <p>{this.state.email.length !== 0 ? "IZMENI" : "DODAJ"}</p>
                                            </button>
                                        </>}
                                </div>
                                <div className="notification-container">
                                    <div className="notification-off">
                                        <div className="slider">
                                            <input type="checkbox" name=""
                                                id="notification-off"
                                                checked={this.state.enableNotifications}
                                                onChange={this.onChange} />
                                            <label htmlFor="notification-off"><p>{this.state.enableNotifications ? "ISKLJUČI NOTIFIKACIJE" : "UKLJUČI NOTIFIKACIJE"}</p></label>
                                            <label htmlFor="notification-off" className="custom-slider">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="notification-sound-off">
                                        <div className="slider">
                                            <input type="checkbox" name=""
                                                id="sound-off"
                                                checked={this.state.enableNotificationSound}
                                                onChange={this.onChange}
                                                disabled={!this.state.enableNotifications} />
                                            <label htmlFor="sound-off"><p>{this.state.enableNotificationSound ? "ISKLJUČI ZVUK" : "UKLJUČI ZVUK"}</p></label>
                                            <label htmlFor="sound-off" className="custom-slider">
                                                <span ></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="orders-and-points">
                                    <div>
                                        <p>BROJ NARUDŽBINA:</p>
                                        <div className="info-total-orders">
                                            <h1>{this.state.user.orders.length}</h1>
                                        </div>
                                    </div>
                                    <div>
                                        <p>BROJ BODOVA:</p>
                                        <div className="info-total-orders">
                                            <h1>{this.state.user.giftPoints}</h1>
                                        </div>
                                    </div>
                                </div>
                                {/* <h1>Avatar: {this.state.user.img.normal}</h1> */}
                            </div>
                        </div>
                    </>;
                break;

            default:
                content = <></>;
                break;
        }
        return (
            <div className="my-profile-container">
                <div className="my-profile-aside-menu">
                    <AsideMenu
                        user={this.state.user}
                        onMenuClick={this.onMenuSelectionChange}
                        selectedMenu={this.state.selectedMenu}
                        loading={showLoader}
                    />
                </div>
                <div className="my-profile-content">
                    {showLoader ?
                        <Loader /> :
                        <>
                            {this.state.showOrderInfoModal ?
                                <OrderInfoModal
                                    order={this.state.selectedOrder}
                                    dbHandler={this.props.dbHandler}
                                    user={this.state.user}
                                    onClose={() => {
                                        this.setState({
                                            showOrderInfoModal: false,
                                            selectedOrder: null,
                                        });
                                    }} /> : null}
                            {content}
                        </>
                    }
                </div>
            </div>
        )
    }
});

export default MyProfile
