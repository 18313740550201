import '../styles/Partners.css';
import WorkingTime from '../model/workingTime';
var createReactClass = require('create-react-class');

var DisplayContainer = createReactClass({
    getInitialState: function () {
        if (this.props.displayType === "client") {
            let workingTime = new WorkingTime();
            if (this.props.client) {
                workingTime = this.props.client.getWorkingTimeForCurrentDay();
            }
            return {
                workingTime: workingTime
            }
        }
        else if (this.props.displayType === "menu") {
            return {
                item: this.props.item
            }
        }
        else {
            return null;
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.item !== prevProps.item) {
            this.setState({
                item: this.props.item
            });
        }
    },
    render: function () {
        if (this.props.displayType === "client") {
            return (
                <>
                    {this.props.client.isWorking() ?
                        <>
                            {this.props.client.isOverloaded ?
                                <div className="store-closed" onClick={this.props.onClick}>
                                    <img src="./images/regular/Prebukirano.svg" alt="Lokal prebukiran" />
                                </div> : null}
                        </> :
                        <>
                            <div className="store-closed" onClick={this.props.onClick}>
                                <img src="./images/regular/Zatvoreno.svg" alt="Lokal zatvoren" />
                            </div>
                        </>}
                    <div className="single-partner-display-unit" onClick={this.props.onClick}>
                        <div className="single-dispaly-unit-patner-img">
                            <img src={(this.props.client.img.normal === "" ? "../images/shop.png" : this.props.client.img.normal)} alt="Profil slika lokala" />
                        </div>
                        <div className="display-unit-image">
                            <img src={(this.props.client.headerImg.normal === "" ? "../images/shop.png" : this.props.client.headerImg.normal)} alt="Naslovna slika lokala" />
                        </div>
                        <div className="single-partner-display-footer">
                            <h2>{this.props.client.name}</h2>
                            <p className="partner-address">{this.props.client.locations[0].address}</p>
                            <p className="partner-delivery">Dostava: {this.state.workingTime ? this.state.workingTime.toString(true) : "Ne radi"}</p>
                            {/* <h4>Za poneti:<br /> {this.state.workingTime ? this.state.workingTime.toString(true) : "Ne radi"}</h4> */}
                            {/* <h4 className="display-unit-tags">{getClientTypeNames(this.props.client.types, this.props.clientTypes).join(', ')}</h4> */}
                        </div>
                    </div>
                </>
            )
        }
        else if (this.props.displayType === "menu") {
            return (
                <div className="single-display-unit" onClick={() => { this.props.onClick(this.state.item, this.props.menu) }}>
                    <div className="display-unit-image">
                        {this.state.item.isImgFromWeb ? <div className="symbolic-photo">
                            <p>Slika je simbolična</p>
                        </div> : null}
                        {this.state.item.img.normal ?
                            <img src={this.state.item.img.normal} alt={this.state.item.img.alt} /> :
                            this.props.client && this.props.client.img.normal ?
                                <img src={this.props.client.img.normal} alt="Slika izabranog jela" /> :
                                <img src="../images/shop.png" alt="Slika izabranog jela" />}
                    </div>
                    <h4>{this.state.item.name}</h4>
                    <p>{this.state.item.description}</p>
                    <h4 className="display-unit-price">
                        {this.state.item.use_price ? "" + this.state.item.price : "Od " + this.state.item.getPriceFromSubItems()}
                        ‎‎&nbsp;RSD
                    </h4>
                </div>
            )
        }
        else {
            return (
                <div className="single-display-unit" onClick={this.props.onClick}>
                    <h2>No display type selected</h2>
                </div>
            )
        }
    }
});

export default DisplayContainer;