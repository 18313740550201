/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import NoData from '../common/NoData.jsx';
import ShoppingCart from './ShoppingCart';
import Loader from './Loader.jsx';
import LoaderSmall from '../common/LoaderSmall.jsx';
import StoreItemDisplayModal from "./StoreItemDisplayModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/StoreStand.css';
import '../styles/MainStyle.css';
import '../styles/Partners.css';
import AsideMenu from './AsideMenu';
import StoreCart from "../model/storeCart";
import WorkingTimeHelper from "../common/WorkingTimeHelper";
import StoreStandInformation from "./StoreStandInformation";
import StoreStandRating from './StoreStandRating';
import StoreStandClientItems from "./StoreStandClientItems";
import { faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Client } from "../model/client.ts";
import MenuItem from "../model/menuItem.ts";
import Cookies from 'js-cookie';
import MessageBox from '../common/MessageBox.jsx';
import { disableScroll, enableScroll } from '../common/effectsHelper.js';
import enumOrderPayer from '../model/enums/orderPayer';
var createReactClass = require('create-react-class');

var StoreStand = createReactClass({
    getInitialState: function () {
        let _client = new Client();
        let _clientTypeName = "";
        if (this.props.clientTypes) {
            this.props.clientTypes.forEach(clientType => {
                if (clientType.id === this.props.selectedClientType) {
                    _clientTypeName = clientType.name;
                }
            });
        }
        if (typeof this.props.client === "string") {
            _client.id = this.props.client;
            this.props.dbHandler.getClientsByIdsDB([this.props.client], null, false, this.onClientLoadDbFinish);
        }
        else {
            _client = this.props.client;
            this.props.dbHandler.getMenusByClientIdDB(_client, this.onDbFinish);
        }
        const storeCart = this.getCartFromCookie(_client.id);
        storeCart.client = this.props.client;
        return {
            clientTypes: this.props.clientTypes,
            selectedClientType: this.props.selectedClientType,
            dbHandler: this.props.dbHandler,
            client: _client,
            loading: true,
            menus: {},
            selectedItem: null,
            selectedMenu: null,
            showItemModal: false,
            storeCart: storeCart,
            workingTime: WorkingTimeHelper.getWorkingTimeForCurrentDay(_client.workingTime),
            selectedTab: "items",
            openShoppingCart: false,
            openAsideMenu: false,
            clientTypeName: _clientTypeName,
            user: this.props.user,
            addingToFavorites: false,
            showMessage: false,
            messageContent: "",
            showDeliveryPrice: false,
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.clientTypes !== prevProps.clientTypes) {
            let _clientTypeName = "";
            this.props.clientTypes.forEach(clientType => {
                if (clientType.id === this.props.selectedClientType) {
                    _clientTypeName = clientType.name;
                }
            });
            this.setState({
                clientTypes: this.props.clientTypes,
                clientTypeName: _clientTypeName
            });
        }
        if (this.props.selectedClientType !== prevProps.selectedClientType) {
            this.setState({
                selectedClientType: this.props.selectedClientType
            });
        }
        if (this.props.client !== prevProps.client) {
            this.setState({
                client: this.props.client,
                listClient: true,
            });
        }
        if (this.props.user !== prevProps.user) {
            this.setState({
                user: this.props.user,
            });
        }
    },
    componentDidMount: function () {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
        this.setState({});
    },
    componentWillUnmount: function () {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    },
    onBackButtonEvent: function (event) {
        event.preventDefault();
        this.state.storeCart.emptyCart();
        this.bakeCartToCookie();
        if (this.props.backFunction) {
            this.props.backFunction();
        }
        else {
            console.log("Back function is not set!");
        }
    },
    onDbFinish: function (client) {
        this.setState({
            loading: false,
            menus: client.menus
        }, this.generateInfoMessage());
    },
    onClientLoadDbFinish: function (clientsArray) {
        if (clientsArray && clientsArray[0]) {
            this.props.dbHandler.getMenusByClientIdDB(clientsArray[0], this.onDbFinish);
            this.state.storeCart.client = clientsArray[0];
            this.setState({
                client: clientsArray[0],
                menus: this.state.client.menus,
                workingTime: WorkingTimeHelper.getWorkingTimeForCurrentDay(clientsArray[0].workingTime)
            });
        }
        else {
            console.log("DB returned empty collection!");
        }
    },
    generateInfoMessage: function () {
        if (this.state.client.isOverloaded) {
            this.setState({
                showMessage: true,
                messageContent: "Lokal je prebukiran. Možete kreirati porudžbinu, ali postoji mogućnost da je lokal odbije usled velikog obima posla.",
            });
        }
        if (!this.state.client.isWorking()) {
            this.setState({
                showMessage: true,
                messageContent: "Lokal trenutno ne radi. Možete kreirati porudžbinu i ona će biti među prvim za obradu nakon otvaranja.",
            });
        }
    },
    onItemClick: function (item, menu) {
        this.setState({
            selectedItem: item,
            showItemModal: true,
            selectedMenu: menu,
        });
    },
    onModalClose: function (addToCart = false, menuItemsToAdd = []) {
        const selectedItem = this.state.selectedItem;
        if (addToCart) {
            if (this.state.user && !this.state.user.blocked) {
                if (this.state.selectedMenu.useMeInOtherItems) {
                    let added = false;
                    this.state.storeCart.items.forEach(storeCartItem => {
                        if (storeCartItem.name === this.state.selectedItem.name) {
                            storeCartItem.itemCount += this.state.selectedItem.itemCount;
                            added = true;
                        }
                    });
                    if (!added) {
                        this.state.storeCart.addItem(this.state.selectedItem, this.state.selectedItem.itemCount);
                    }
                }
                else {
                    this.state.storeCart.addItem(this.state.selectedItem, this.state.selectedItem.itemCount);
                }

                menuItemsToAdd.forEach(menuItem => {
                    if (menuItem) {
                        let added = false;
                        this.state.storeCart.items.forEach(storeCartItem => {
                            if (storeCartItem.name === menuItem.name) {
                                storeCartItem.itemCount++;
                                added = true;
                            }
                        });
                        if (!added) {
                            this.state.storeCart.addItem(menuItem, this.state.selectedItem.itemCount);
                        }
                    }
                });
                this.bakeCartToCookie();
                this.setState({
                    selectedItem: null,
                    selectedMenu: null,
                    showItemModal: false
                });
            }
            else {
                if (!this.state.user) {
                    this.setState({
                        selectedItem: null,
                        selectedMenu: null,
                        showItemModal: false,
                        showMessage: true,
                        messageContent: "Dodavanje u korpu i naručivanje nije moguće ukoliko niste ulogovani.",
                    });
                }
                else if (this.state.user.blocked) {
                    this.setState({
                        selectedItem: null,
                        selectedMenu: null,
                        showItemModal: false,
                        showMessage: true,
                        messageContent: <>
                            Dodavanje u korpu i naručivanje nije moguce jer vam je nalog blokiran.<br />
                            Kontaktirajte operatera za više informacija.</>,
                    });
                }
            }
        }
        else {
            this.setState({
                selectedItem: null,
                showItemModal: false,
                selectedMenu: null,
            });
        }
        selectedItem.itemCount = 1;
    },
    onMenuSelectionChange: function (menuIndex) {
        if (menuIndex === "all") {
            this.setState({
                menus: this.state.client.menus,
                openAsideMenu: false
            });
            this.toggleWindowScroll(false);
        }
        else {
            let menus = { [Number(menuIndex)]: this.state.client.menus[Number(menuIndex)] };
            this.setState({
                menus: menus,
                openAsideMenu: false
            });
            this.toggleWindowScroll(false);
        }
    },
    onCheckBoxChange: function (event) {
        let newValue = event.target.checked;
        switch (event.target.id) {
            case "open-shopping-cart":
                this.setState({ openShoppingCart: newValue });
                this.toggleWindowScroll(newValue);
                break;
            case "open-aside-menu":
                this.setState({ openAsideMenu: newValue });
                this.toggleWindowScroll(newValue);
                break;
            default:
                break;
        }
    },
    toggleWindowScroll: function (toggleOff) {
        if (toggleOff) {
            disableScroll();
        }
        else {
            enableScroll();
        }
    },
    onTabChange: function (tabName) {
        this.setState({ selectedTab: tabName })
    },
    onCartChange: function () {
        this.bakeCartToCookie();
        this.setState({ cartChanged: true })
    },
    generateBodyContent: function () {
        if (this.state.loading) {
            return (<Loader />);
        }
        switch (this.state.selectedTab) {
            case "items":
                return (
                    <StoreStandClientItems
                        menus={this.state.menus}
                        client={this.state.client}
                        onClick={this.onItemClick} />
                );
            case "info":
                return (
                    <StoreStandInformation
                        client={this.state.client}
                        clientTypes={this.state.clientTypes}
                        selectedClientType={this.state.selectedClientType} />
                );
            case "impressions":
                return (
                    <StoreStandRating
                        client={this.state.client}
                        clientTypes={this.state.clientTypes}
                        selectedClientType={this.state.selectedClientType} />
                );

            default:
                return (<NoData />);
        }
    },
    isClientInFavorites: function () {
        return this.state.user.favorites.some(x => (x === this.state.client.id));
    },
    addToFavorites: function () {
        if (!this.isClientInFavorites()) {
            this.state.user.favorites.push(this.state.client.id);
            this.state.dbHandler.updateUserDB(this.state.user, () => { this.setState({ addedToFavorites: true, addingToFavorites: false }) });
            this.setState({ addingToFavorites: true });
        }
    },
    bakeCartToCookie: function () {
        //const api = Cookies.withAttributes({ path: this.state.client.id })
        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach(cookieId => {
            const cookieIdSplitArray = cookieId.split("~");
            if (cookieIdSplitArray && cookieIdSplitArray[0] && cookieIdSplitArray[1] &&
                cookieIdSplitArray[0] === this.state.client.id &&
                cookieIdSplitArray[1] === "cartItem") {
                Cookies.remove(cookieId, { path: this.state.client.id });
            }
        });
        this.state.storeCart.items.forEach((item, index) => {
            const cookieIdToSet = this.state.client.id + "~cartItem~" + index;
            Cookies.set(cookieIdToSet, JSON.stringify(item.toJson()), { path: this.state.client.id, expires: 0.5 });
        });
    },
    getCartFromCookie: function (clientID) {
        const allCookies = Cookies.get();
        const cart = new StoreCart()
        Object.keys(allCookies).forEach(cookieId => {
            const cookieIdSplitArray = cookieId.split("~");
            try {
                if (cookieIdSplitArray && cookieIdSplitArray[0] && cookieIdSplitArray[1] &&
                    cookieIdSplitArray[0] === clientID &&
                    cookieIdSplitArray[1] === "cartItem") {
                    const cartItem = new MenuItem();
                    cartItem.loadFromJson(JSON.parse(allCookies[cookieId]));
                    cart.addItem(cartItem, cartItem.itemCount);
                }
            }
            catch (ex) {
                console.log("Error while getting cart item from cookie.");
                console.log(ex);
                console.log("Deleting cookie.");
                Cookies.remove(cookieId, { path: clientID });
            }
        });
        return cart;
    },
    render: function () {
        return (
            <div className="store-stand-container">
                {this.state.showItemModal ?
                    <StoreItemDisplayModal
                        selectedMenu={this.state.selectedMenu}
                        onClose={this.onModalClose}
                        itemToShow={this.state.selectedItem}
                        client={this.state.client} />
                    : null}
                {this.state.showMessage ?
                    <MessageBox title="Info" content={this.state.messageContent}
                        onClick={() => { this.setState({ showMessage: false, messageContent: "" }) }} />
                    : null}
                <div className="store-stand-header" style={{ backgroundImage: `url(${(this.state.client.headerImg.normal === "" ? "../images/pattern.svg" : this.state.client.headerImg.normal)})` }}>
                    <div className="store-stand-info black-transparency">
                        {this.state.user ?
                            this.state.addingToFavorites ?
                                <button
                                    onClick={this.addToFavorites}
                                    className="add-to-favorite">
                                    <LoaderSmall />
                                </button> :
                                <button
                                    onClick={this.addToFavorites}
                                    className={("add-to-favorite " + (this.isClientInFavorites() ? "added-to-favorites" : "not-added-to-favorites"))}>
                                </button>
                            : null}
                        <div className="tooltip">
                            <span className="tooltiptext-not-added">Dodaj u omiljene</span>
                            <span className="tooltiptext-added">Dodato u omiljene</span>
                        </div>
                        <div className="store-stand-info-content">
                            <div className="store-stand-info-head">
                                <div className="store-name">
                                    <div className="name-rating">
                                        <p className="name">{this.state.client.name}</p>
                                        {/* <div className="store-rating">
                                            <img src="images/small_img/star-rating.svg" alt="Ikonica za rejting lokala" />
                                            <p className="rating">0.0</p>
                                        </div> */}
                                    </div>
                                    <div className="store-name-location-info">
                                        <p className="info">{this.state.clientTypeName}</p>
                                        {this.state.client.locations && this.state.client.locations[0] && this.state.client.locations[0].address ?
                                            <p className="info">{this.state.client.locations[0].address}</p> : <></>}
                                    </div>
                                </div>
                                <div className="work-hours">
                                    <div className="store-work-hours">
                                        <p className="info">Radno vreme:</p>
                                        <p>{this.state.workingTime ? this.state.workingTime.toString() : "Ne radi"}</p>
                                    </div>
                                    <div className="delivery-work-hours">
                                        <p className="info">Dostava:</p>
                                        <p>{this.state.workingTime ? this.state.workingTime.toString(true) : "Ne radi"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="store-stand-info-body">
                                <div className="store-stand-info-body-icons">
                                    <img src="images/small_img/delivery-truckk.svg" alt="Dostava ikonica" />
                                    {/* TODO: Move price to delivery object defined in DB */}
                                    <p>
                                        {this.state.client.orderPayer === enumOrderPayer.USER ?
                                            (this.state.client.minimumDeliveryCost > 0 ?
                                                "od " + this.state.client.minimumDeliveryCost + " RSD" : "od 250 RSD") :
                                            "0 RSD"}

                                    </p>
                                    <div className="delivery-price-info">
                                        <i onClick={() => { this.setState({ showDeliveryPrice: true }) }}>
                                            <FontAwesomeIcon icon={faQuestion} className="aside-menu" />
                                        </i>
                                    </div>
                                </div>
                                <div className="store-stand-info-body-icons">
                                    <img src="images/small_img/fast-time.svg" alt="Vreme dostave ikonica" />
                                    <p>60'</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="store-stand-body">
                    {this.state.showDeliveryPrice ?
                        this.state.client.orderPayer === enumOrderPayer.USER ?
                            <div className="delivery-price-container">
                                <div className="delivery-price-content">
                                    <div className="delivery-price-header">
                                        <p onClick={() => { this.setState({ showDeliveryPrice: false }) }}>X</p>
                                    </div>
                                    <img src="./images/regular/mapacenovnika.svg" alt="Cenovnik mapa" />
                                    <div className="delivery-price-footer">
                                        <p>Detaljan cenovnik na "Cenovnik dostave" stranici</p>
                                    </div>
                                </div>
                            </div> :
                            <>
                                <MessageBox title="Info" content={(this.state.client.orderPayer === enumOrderPayer.CLIENT) ?
                                    "Dostavu na Vašu adresu će obezbediti lokal o svom trošku." :
                                    "Dostavu na Vašu adresu obezbeđuje deMango o svom trošku."}
                                    onClick={() => { this.setState({ showDeliveryPrice: false }) }} />
                            </> : null}
                    <div className="store-stand-body-content">
                        {this.state.selectedTab === "items" ?
                            <>
                                {/* Input that controls opening and closing of responsive aside menu */}
                                <input type="checkbox" id="open-aside-menu" onChange={this.onCheckBoxChange} checked={this.state.openAsideMenu} />
                                <label htmlFor="open-aside-menu" className="open-aside-menu-icon">
                                    <i>
                                        <img src="./images/regular/cutlery.svg" alt="Ikonica menija" className="aside-menu" />
                                    </i>
                                </label>
                                <label htmlFor="open-aside-menu" className="close-aside-menu slide-left">
                                    <i><FontAwesomeIcon icon={faTimes} className="aside-menu" /></i>
                                </label>
                            </> : null
                        }
                        <div className="store-stand-side-menu">
                            <AsideMenu
                                // clientTypes={this.props.clientTypes}
                                // onTypeClick={this.props.onTypeChange}
                                onMenuClick={this.onMenuSelectionChange}
                                selectedClientType={this.props.selectedClientType}
                                client={this.state.client}
                            />
                        </div>
                        {/* scrollbar */}
                        <div className="store-stand-display-body">
                            <div className="store-stand-display-body-cards">
                                <div className={("store-stand-card " + (this.state.selectedTab === "items" ? "underline" : ""))}
                                    onClick={() => { this.onTabChange("items") }}>
                                    <p>Proizvodi</p>
                                </div>
                                <div className={("store-stand-card " + (this.state.selectedTab === "info" ? "underline" : ""))}
                                    onClick={() => { this.onTabChange("info") }}>
                                    <p>Informacije</p>
                                </div>
                                <div className={("store-stand-card " + (this.state.selectedTab === "impressions" ? "underline" : ""))}
                                    onClick={() => { this.onTabChange("impressions") }}>
                                    <p>Utisci (0)</p>
                                </div>
                            </div>
                            {this.generateBodyContent()}
                        </div>
                        {this.state.selectedTab === "items" ?
                            <>
                                {/* Input that controls opening and closing of responsive shopping cart */}
                                <input type="checkbox" id="open-shopping-cart" onChange={this.onCheckBoxChange} checked={this.state.openShoppingCart} />
                                <label htmlFor="open-shopping-cart" className="open-shopping-cart-icon">
                                    <i>
                                        <img src="./images/regular/shopping-cart.svg" alt="Ikonica korpe" className="shopping-cart" />
                                    </i>
                                    <div className="shopping-cart-item-number">
                                        <p>{this.state.storeCart.getItemsCount()}</p>
                                    </div>
                                </label>
                                <label htmlFor="open-shopping-cart" className="close-shopping-cart slide-left">
                                    <i><FontAwesomeIcon icon={faTimes} className="shopping-cart" /></i>
                                </label>
                            </> : null}
                        <div className="store-stand-shopping-cart-container">
                            <ShoppingCart
                                storeCart={this.state.storeCart}
                                client={this.state.client}
                                dbHandler={this.state.dbHandler}
                                authManager={this.props.authManager}
                                user={this.state.user}
                                onCartChange={this.onCartChange} />
                        </div>
                    </div>
                </div>
            </div >
        )
    }
});

export default StoreStand
