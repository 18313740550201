import Order from '../model/order';
import ClientType from '../model/clientType';
import Client from '../model/client';
import Menu from "../model/menu";
import User from "../model/user";
import Address from "../model/address";
import Notification from '../model/notification';
import { db, functions } from './Firebase.jsx';
import { httpsCallable } from "firebase/functions";
import { doc, collection, onSnapshot, getDoc, deleteDoc } from "firebase/firestore";


class DBHandler {
    initializeVersionListener(onChangeFunction) {
        const refVersion = doc(db, "appVersions", "deMangoClient");
        onSnapshot(refVersion, (querySnapshot) => {
            const retObj = querySnapshot.data();
            let version = 0;
            if (retObj && retObj.version) {
                version = retObj.version
            }
            if (onChangeFunction) {
                onChangeFunction(version);
            }
        });
    }

    initializeCountDownListener(onChangeFunction) {
        const refSettings = doc(db, "settings", "deMangoClient")
        onSnapshot(refSettings, (querySnapshot) => {
            const retObj = querySnapshot.data();
            if (onChangeFunction) {
                onChangeFunction(retObj);
            }
        });
    }

    initializeUserNotificationsListener(userId, onChangeFunction) {
        const refNotifications = collection(db, "users", userId, "notifications");
        try {
            onSnapshot(refNotifications, (querySnapshot) => {
                const notificationObjects = [];
                querySnapshot.forEach((notification) => {
                    const notifyObj = new Notification(null);
                    notifyObj.loadFromJson(notification.data());
                    notifyObj.loadFromJson(notification);
                    notificationObjects.push(notifyObj);
                });
                if (onChangeFunction) {
                    onChangeFunction(notificationObjects);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    async getFooterContent(onDbLoadFinish) {
        const refFooterContent = doc(db, "siteContent", "deMangoClient", "sr", "footerLinksContent");
        try {
            const docSnap = await getDoc(refFooterContent);
            if (onDbLoadFinish) {
                onDbLoadFinish(docSnap.data());
            }
        } catch (error) {
            console.log(error);
            if (onDbLoadFinish) {
                onDbLoadFinish();
            }
        }
    }

    async deleteNotification(notificationId, userId, onDeleteFunction) {
        const refNotification = doc(db, "users", userId, "notifications", notificationId);
        try {
            await deleteDoc(refNotification);
        } catch (error) {
            console.log(error);
        }
        if (onDeleteFunction) {
            onDeleteFunction();
        }
    }

    async addOrUpdateOrderDB(order, onDbFinish) {
        const addOrderFunc = httpsCallable(functions, 'addOrderToDB');
        try {
            const result = await addOrderFunc({ order: order.toJason() });
            if (result.error) {
                console.log(result);
            }
            if (onDbFinish) {
                onDbFinish();
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
        }
    }

    async getOrderByIdDB(orderId, onDbFinish) {
        const getOrderByIdFunc = httpsCallable(functions, 'getOrderById');
        try {
            const result = await getOrderByIdFunc({ orderId: orderId });
            if (result.error) {
                console.log(result);
            }
            if (onDbFinish) {
                onDbFinish(result);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
        }
    }

    async getOrdersByIdsDB(orderIds, sequence, useSequence, onDbFinish) {
        const getOrderByIdsFunc = httpsCallable(functions, 'getOrdersByIds');
        try {
            const orderIdsToFind = [];
            orderIds.forEach(id => {
                if (id) {
                    orderIdsToFind.push(id);
                }
            });
            const result = await getOrderByIdsFunc({ orderIds: orderIdsToFind, sequence: sequence, useSequence: useSequence });
            const ordersObj = [];
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.orders && result.data.orders.length > 0) {
                result.data.orders.forEach((orderJson) => {
                    let tempOrderObj = new Order();
                    tempOrderObj.loadFromJson(orderJson);
                    ordersObj.push(tempOrderObj);
                });
            }
            else {
                console.log("There is no Data in order DB respond!");
            }

            if (onDbFinish) {
                onDbFinish(ordersObj);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish([]);
            }
        }
    }

    async addOrderRatingDB(rating, onDbFinish) {
        const addOrderRatingFunc = httpsCallable(functions, 'addOrderRating');
        try {
            const result = await addOrderRatingFunc({ rating: rating.toJason() });
            if (result.error) {
                console.log(result);
            }
            if (onDbFinish) {
                onDbFinish(result);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(ex);
            }
        }
    }

    //#endregion  Orders DB functions

    async getClientTypesDB(onDbFinish) {
        let clientTypes = [];
        const getClientTypesFunc = httpsCallable(functions, 'getClientTypes');
        try {
            const result = await getClientTypesFunc();
            if (result.data) {
                Object.values(result.data).forEach((clientTypeJSON) => {
                    let clientTypeObj = new ClientType();
                    clientTypeObj.loadFromJson(clientTypeJSON);
                    clientTypes.push(clientTypeObj);
                })
            }
            if (onDbFinish) {
                onDbFinish(clientTypes);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(clientTypes);
            }
        }
    }

    async getClientsByTypeDB(clientType, onDbFinish) {
        let clients = [];
        const getClientsByType = httpsCallable(functions, 'getClientsByType');
        try {
            const result = await getClientsByType({ clientType: clientType });
            if (result.data) {
                Object.values(result.data).forEach((clientsJSON) => {
                    let clientObj = new Client();
                    clientObj.loadFromJson(clientsJSON);
                    clients.push(clientObj);
                })
            }
            if (onDbFinish) {
                onDbFinish(clients);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(clients);
            }
        }
    }

    async getClientsByIdsDB(ids, sequence, useSequence, onDbFinish) {
        const getClientsByIdsFunc = httpsCallable(functions, 'getClientsByIds');
        const clientsObj = [];
        try {
            const result = await getClientsByIdsFunc({ clientIds: ids, sequence: sequence, useSequence: useSequence });
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.clients && result.data.clients.length > 0) {
                result.data.clients.forEach(clientJson => {
                    let tempClientObj = new Client();
                    tempClientObj.loadFromJson(clientJson);
                    clientsObj.push(tempClientObj);
                });
            }
            else {
                console.log("There is no Data in order DB respond!");
                console.log(result);
            }

            if (onDbFinish) {
                onDbFinish(clientsObj);
            }

        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(clientsObj);
            }
        }
    }

    async getMenusByClientIdDB(client, onDbFinish) {
        const getMenusByClientId = httpsCallable(functions, 'getMenusByClientId');
        try {
            const result = await getMenusByClientId({ clientId: client.id });
            if (result.error) {
                console.log(result);
            }
            else if (result.data) {
                Object.values(result.data.menus).forEach((menuJson) => {
                    let menuObj = new Menu();
                    menuObj.loadFromJson(menuJson);
                    client.addMenu(menuObj);
                })
            }
            else {
                console.log("There is no Data in order DB respond!");
                console.log(result);
            }
            if (onDbFinish) {
                onDbFinish(client);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(client);
            }
        }
    }

    async addUserDB(user, onDbFinish) {
        const addUserFunc = httpsCallable(functions, 'addUser');
        try {
            const result = await addUserFunc({ user: user.toJason() });
            if (result.error) {
                console.log(result);
            }
            if (onDbFinish) {
                onDbFinish();
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish();
            }
        }
    }

    async updateUserDB(user, onDbFinish) {
        const updateUserFunc = httpsCallable(functions, 'updateUser');
        try {
            const result = await updateUserFunc({ user: user.toJason() });
            if (result.error) {
                console.log(result);
            }
            if (onDbFinish) {
                onDbFinish();
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish();
            }
        }
    }

    async getUserByIdDB(userId, onDbFinish) {
        const getUserByIDFunc = httpsCallable(functions, 'getUserByID');
        let userObj = null;
        try {
            const result = await getUserByIDFunc({ userId: userId });
            if (result.data && result.data.user) {
                userObj = new User();
                userObj.loadFromJson(result.data.user);
            }
            if (onDbFinish) {
                onDbFinish(userObj);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(userObj);
            }
        }
    }

    async getAddressesByIdsDB(ids, onDbFinish) {
        const getAddressesByIdsFunc = httpsCallable(functions, 'getAddressesByIds');
        const addressesObj = [];
        try {
            const result = await getAddressesByIdsFunc({ addressesIds: ids });
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.addresses && Object.values(result.data.addresses).length > 0) {
                Object.values(result.data.addresses).forEach(addressJson => {
                    let tempAddressObj = new Address();
                    tempAddressObj.loadFromJson(addressJson);
                    addressesObj.push(tempAddressObj);
                });
            }
            else {
                console.log("There is no Data in order DB respond!");
            }
            if (onDbFinish) {
                onDbFinish(addressesObj);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(addressesObj);
            }
        }
    }
}

export default DBHandler
