import MenuItem from "./menuItem";
import Client from "./client";
import Image from "./image";

class StoreCart {
    private _id: string;
    private _client: Client;
    private _items: Array<MenuItem>;

    constructor() {
        this._id = "";
        this._client = new Client();
        this._items = [];
    }

    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get client(): Client {
        return this._client;
    }
    set client(value: Client) {
        this._client = value;
    }

    get items(): Array<MenuItem> {
        return this._items;
    }
    set items(value: Array<MenuItem>) {
        this._items = value;
    }

    addItem(item: MenuItem, quantity = 1) {
        if (item) {
            const copyItem = new MenuItem();
            copyItem.loadFromJson(item.toJson());
            copyItem.itemCount = quantity;
            this._items.push(copyItem);
            this.generatePackingItems();
        }
    }
    removeItem(item: MenuItem) {
        const index: number = this._items.indexOf(item);
        if (index !== -1) {
            this._items.splice(index, 1);
        }
        this.generatePackingItems();
    }

    generatePackingItems() {
        if (!this.client.packingFee) {
            return;
        }
        const realItems: MenuItem[] = [];
        let packingItem = null;
        let packingItemCount = 0;
        this._items.forEach((menuItem: MenuItem) => {
            if (menuItem.id === "packingFeeItem") {
                packingItem = menuItem;
            }
            else {
                realItems.push(menuItem);
                packingItemCount += menuItem.itemCount;
            }
        });
        if (realItems.length === 0) {
            // Clear all packing items
            this._items = [];
        }
        else {
            if (!packingItem) {
                packingItem = new MenuItem();
                packingItem.id = "packingFeeItem";
                packingItem.name = "Pakovanje";
                packingItem.price = this.client.packingFee;
                packingItem.use_price = true;
                packingItem.description = "Troškovi pakovanja.";
                packingItem.showOnWebsite = true;
                packingItem.items = {}
                const img = new Image();
                // TODO: Move this to site settings.
                img.normal = "https://firebasestorage.googleapis.com/v0/b/demango-f56c2.appspot.com/o/images%2FdefaultImages%2Fbox.svg?alt=media&token=82204f0d-a71b-4864-b52e-6ca1af3ee5ee";
                img.alt = "Slika pakovanja."
                packingItem.img = img;
                this.items.push(packingItem);
            }
            packingItem.itemCount = packingItemCount;
            const newItemArray = realItems;
            newItemArray.push(packingItem);
            this._items = newItemArray;
        }
    }

    emptyCart() {
        this._items = [];
    }

    isEmpty() {
        return (!(this._items && this._items.length > 0));
    }

    getCurrentPrice() {
        let price: number = 0;
        this._items.forEach((item: MenuItem) => {
            price = Number(price) + Number(item.getCurrentPrice());
        });
        return price.toFixed(2);
    }

    getItemsCount(): number {
        let count: number = 0
        this._items.forEach((menuItem: MenuItem) => {
            count += menuItem.itemCount;
        });

        return count;
    }

    toJson() {
        let items_json: Array<{}> = [];
        this._items.forEach((item: MenuItem) => {
            items_json.push(item.toJson());
        });

        return {
            id: this._id,
            client: this._client.toJason(),
            items: items_json,
            price: this.getCurrentPrice()
        }
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.client) {
                const tempClient = new Client()
                tempClient.loadFromJson(jsonObject.client)
                this._client = tempClient;
            }
            if (jsonObject.items) {
                this._items = [];
                jsonObject.items.forEach((itemJson: {}) => {
                    const itemObj = new MenuItem();
                    itemObj.loadFromJson(itemJson);
                    this.addItem(itemObj, itemObj.itemCount);
                });
            }
        }
    }
}

export default StoreCart