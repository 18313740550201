import Loader from './Loader.jsx';
import NoData from '../common/NoData.jsx';
import AsideMenu from '../components/AsideMenu';
import DisplayContainer from './DisplayContainer.jsx';
import DBHandler from "../database/DatabaseHandler";
import '../styles/Partners.css';
var createReactClass = require('create-react-class');


var Partners = createReactClass({
    getInitialState: function () {
        const _dbHandler = new DBHandler();
        _dbHandler.getClientsByTypeDB(this.props.selectedClientType, this.onClientDbLoadFinish);
        return {
            dbHandler: _dbHandler,
            clientTypes: this.props.clientTypes,
            clients: [],
            loading: true
        }
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.clientTypes !== prevProps.clientTypes) {
            this.setState({
                clientTypes: this.props.clientTypes
            });
        }
        if (this.props.selectedClientType !== prevProps.selectedClientType) {
            this.setState({
                selectedClientType: this.props.selectedClientType
            });
        }
        if (this.props.selectedClientType !== prevProps.selectedClientType) {
            this.state.dbHandler.getClientsByTypeDB(this.props.selectedClientType, this.onClientDbLoadFinish);
            this.setState({
                selectedClientType: this.props.selectedClientType,
                clients: [],
                loading: true
            });
        }
    },
    componentDidMount: function () {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
    },
    componentWillUnmount: function () {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    },
    onBackButtonEvent: function (event) {
        event.preventDefault();
        if (this.props.backFunction) {
            this.props.backFunction();
        }
        else {
            console.log("Back function is not set!");
        }
    },
    sortClients: function (clients) {
        const retArray = [].concat(clients)
            .sort((a, b) => a.isWorking() < b.isWorking() ? 1 : -1);
        return retArray
    },
    onClientDbLoadFinish: function (clientsFromDb) {
        const sortedClients = this.sortClients(clientsFromDb);
        this.setState({
            clients: sortedClients,
            loading: false
        });
    },
    render: function () {
        let content = null;
        if (this.state.loading) {
            content = <Loader />
        }
        else if (this.state.clients.length === 0) {
            content =
                <>
                    <div className="no-data-container">
                        <NoData />
                    </div>
                </>
        }
        else {
            content = <>
                {this.state.clients.map((client) => {
                    return (
                        <div className="display-container-wrapper">
                            <DisplayContainer
                                client={client}
                                clientTypes={this.props.clientTypes}
                                onClick={() => { this.props.onClientSelection(client) }}
                                displayType="client"
                            />
                        </div>
                    )
                })}
            </>
        }
        return (
            <div className="partners-container">
                <div className="partners-side-menu">
                    <AsideMenu
                        clientTypes={this.state.clientTypes}
                        onTypeClick={this.props.onTypeChange}
                        selectedClientType={this.props.selectedClientType} />
                </div>
                <div className="partners-show-container">
                    <div className="partners-display-container">
                        {content}
                    </div>
                </div>
                <div className="partners-right-side-photo">
                    {/* <img src="images/testImages/friesbw.jpg" alt="pozadinska slika" /> */}
                </div>
            </div>
        )
    }
});

export default Partners